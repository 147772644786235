/*
 * Route helpers for graph
 *
 * These live outside of the individual page files to allow lazy loading / code
 * splitting.
 */
import _ from 'lodash';
import LZString from 'lz-string';
import * as myzod from 'myzod';
import pathToRegExp from 'path-to-regexp';
import { ParsedQuery, stringify } from 'query-string';

import Config from 'src/lib/config';
import { TimeRangeOption } from 'src/lib/config/timeRangeOptions';
import { GraphQLTypes } from 'src/lib/graphqlTypes';
import {
  NewNormalizedMetricsFilterField,
  NormalizedFieldsMetricsField,
  NormalizedFieldsMetricsFilterField,
  NormalizedMetricsField,
  NormalizedMetricsFilterField,
  metricConfig,
} from 'src/lib/metrics';
import { RouteConfig } from 'src/lib/routeConfig/RouteConfig';

import { ProposeSchemaChangesModalStateFragment } from '../shared/routes';

import { OperationStatus } from './checksPage/checkWorkflowTaskDetailsPage/operationCheckResults/operationsCheckList/OperationsCheckList';

const TimeRangeRouteFragment = new RouteConfig({
  parseSearchParams: (params) =>
    myzod
      .object({
        range: myzod
          .literals(
            ...([
              'lastFiveMinutes',
              'lastHour',
              'lastFourHours',
              'lastDay',
              'lastThreeDays',
              'lastWeek',
              'lastMonth',
              'lastThreeMonths',
              'custom',
            ] as TimeRangeOption[]),
          )
          .optional(),
        to: myzod.string().optional(),
        from: myzod.string().optional(),
        fastModeRange: myzod
          .literals('lastMonth', 'lastThreeMonths')
          .optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});

/**
 * LEGACY RouteConfig for the top-level `/graphs`
 * The same as `graphRouteConfig` as of March 2023, but with `variant` queryParam
 */
export const legacyGraphRouteConfig = new RouteConfig<
  {
    graphVisibilityType?: 'graph' | 'public' | undefined;
    graphId: string;
  },
  {
    variant?: string | undefined;
  },
  unknown,
  unknown
>({
  definition: '/:graphVisibilityType(graph|public)/:graphId',
  parseMatchParams: (params) =>
    myzod
      .object({
        graphVisibilityType: myzod
          .literals('graph', 'public')
          .default('graph')
          .optional(),
        graphId: myzod.string(),
      })
      .allowUnknownKeys()
      .parse(params),
  locationPathname: (params) => {
    return compiledGraphRouteConfigDefinition({
      ...params,
      graphVisibilityType: params.graphVisibilityType ?? 'graph',
    });
  },
  parseSearchParams: (params) =>
    myzod
      .object({
        variant: myzod.string().optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});

/**
 * RouteConfig for the top-level `/graphs`
 * If this changes, please also update paths in packages/studio-ui/functions/handleSiteMap.ts
 */
export const graphRouteConfig = new RouteConfig<
  {
    graphVisibilityType?: 'graph' | 'public' | undefined;
    graphId: string;
  },
  {},
  unknown,
  unknown
>({
  definition: '/:graphVisibilityType(graph|public)/:graphId',
  // XXX(Chang): this parseSearchParams is being called a ridiculous number of times on each variant page (e.g. schema documentation), with each usage of useInternalGraphLinking
  // memoizing the function as a hack to avoid performance slowdowns
  // https://apollographql.atlassian.net/browse/NEBULA-3181
  parseMatchParams: _.memoize(
    (params) => {
      return myzod
        .object({
          graphVisibilityType: myzod
            .literals('graph', 'public')
            .default('graph')
            .optional(),
          graphId: myzod.string(),
        })
        .allowUnknownKeys()
        .parse(params);
    },
    (...args) => {
      return JSON.stringify(args);
    },
  ),
  locationPathname: (params) => {
    return compiledGraphRouteConfigDefinition({
      ...params,
      graphVisibilityType: params.graphVisibilityType ?? 'graph',
    });
  },
});

const compiledGraphRouteConfigDefinition = pathToRegExp.compile(
  graphRouteConfig.definition,
);

export const variantRouteConfig = graphRouteConfig.extend(
  new RouteConfig({
    definition: '/variant/:graphVariant',
    parseMatchParams: (params) => {
      const data = myzod
        .object({ graphVariant: myzod.string() })
        .allowUnknownKeys()
        .parse(params);
      return { ...data, graphVariant: decodeURIComponent(data.graphVariant) };
    },
    locationPathname(params) {
      return pathToRegExp.compile(this.definition)({
        ...params,
        graphVariant: encodeURIComponent(params.graphVariant),
      });
    },
  }),
);
export const proposalRouteConfig = graphRouteConfig.extend(
  new RouteConfig({
    definition: '/proposal/:graphVariant',
    parseMatchParams: (params) => {
      const data = myzod
        .object({ graphVariant: myzod.string() })
        .allowUnknownKeys()
        .parse(params);
      return { ...data, graphVariant: decodeURIComponent(data.graphVariant) };
    },
    locationPathname(params) {
      return pathToRegExp.compile(this.definition)({
        ...params,
        graphVariant: encodeURIComponent(params.graphVariant),
      });
    },
  }),
);

const DeleteProposalCommentModalRouteFragment = new RouteConfig({
  parseSearchParams: (params) =>
    myzod
      .object({
        commentThreadId: myzod.string().optional(),
        proposalCommentId: myzod.string().optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});

type ProposalEditorHashParams = {
  linkedCoordinateName?: string;
};

export const proposalEditorRouteConfig = proposalRouteConfig
  .extend(DeleteProposalCommentModalRouteFragment)
  .extend(
    new RouteConfig({
      definition: '/editor',
      parseSearchParams: (params) =>
        myzod
          .object({
            selectedSchema: myzod.string().optional(),
            newSubgraphName: myzod.string().optional(),
          })
          .allowUnknownKeys()
          .parse(params),
      parseHash: (hash) => {
        return hash.length ? { linkedCoordinateName: hash } : {};
      },
      locationHash: ({ linkedCoordinateName }: ProposalEditorHashParams) =>
        linkedCoordinateName ?? '',
    }),
  );

type ProposalChangesHashParams = {
  // this is the group name for each group of detailed changes in the # of the changes route
  linkedGroupName?: string;
  // this is the coordinate name that allows us to link to specific coordinates on subgraphs
  linkedCoordinateName?: string;
};

export const proposalChangesRouteConfig = proposalRouteConfig
  .extend(DeleteProposalCommentModalRouteFragment)
  .extend(
    new RouteConfig({
      definition: '/changes',
      parseSearchParams: (params) =>
        myzod
          .object({
            selectedSchema: myzod.string().optional(),
            includeImplementedChanges: myzod.string().optional(),
            selectedRevision: myzod.string().optional(),
          })
          .allowUnknownKeys()
          .parse(params),
      parseHash: (hash) => {
        const [linkedGroupName, ...rest] = hash.split('.');
        return linkedGroupName.length
          ? rest.length
            ? { linkedGroupName, linkedCoordinateName: rest.join('.') }
            : { linkedGroupName }
          : {};
      },
      locationHash: ({
        linkedGroupName,
        linkedCoordinateName,
      }: ProposalChangesHashParams) =>
        linkedGroupName
          ? linkedCoordinateName
            ? `${linkedGroupName}.${linkedCoordinateName}`
            : linkedGroupName
          : '',
    }),
  );

const ProposalListRouteFragment = new RouteConfig({
  definition: '/proposals',
  parseSearchParams: (params) =>
    myzod
      .object({
        page: myzod.number().coerce().optional(),
        filterByStatuses: myzod
          .array(myzod.enum(GraphQLTypes.ProposalStatus))
          .coerce((value) =>
            Object.values<string>(GraphQLTypes.ProposalStatus).includes(value)
              ? [value as GraphQLTypes.ProposalStatus]
              : [],
          )
          .optional(),
        filterBySubgraphs: myzod
          .array(myzod.string())
          .coerce((value) => [value])
          .optional(),
        filterByVariants: myzod
          .array(myzod.string())
          .coerce((value) => [value])
          .optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});

export const graphProposalListRouteConfig = graphRouteConfig
  .extend(ProposalListRouteFragment)
  .extend(ProposeSchemaChangesModalStateFragment);

export const variantProposalListRouteConfig = variantRouteConfig
  .extend(ProposalListRouteFragment)
  .extend(
    new RouteConfig({
      definition: '/:view(graph|variant)',
      parseMatchParams: (params) =>
        myzod
          .object({
            view: myzod.literals('graph', 'variant'),
          })
          .allowUnknownKeys()
          .parse(params),
    }),
  )
  .extend(ProposeSchemaChangesModalStateFragment);

export const persistedQueriesRouteConfig = graphRouteConfig.extend(
  new RouteConfig({ definition: '/persisted-queries' }),
);

export const persistedQueryListRouteConfig = persistedQueriesRouteConfig.extend(
  new RouteConfig({
    definition: '/list/:listId',
    parseMatchParams: (params) =>
      myzod.object({ listId: myzod.string() }).allowUnknownKeys().parse(params),
  }),
);

export const upgradeRouteConfig = variantRouteConfig.extend(
  new RouteConfig({
    definition: '/upgrade',
  }),
);

export const variantHomepageRouteConfig = variantRouteConfig.extend(
  new RouteConfig({
    definition: '/home',
  }),
);
export const proposalHomepageRouteConfig = proposalRouteConfig
  .extend(
    new RouteConfig({
      definition: '/home',
      parseSearchParams: (params) =>
        myzod
          .object({
            // These params are distinct from the similar params below to prevent
            // closing of the thread after the user uses the Delete Comment modal
            focusCommentId: myzod.string().optional(),
            focusThreadId: myzod.string().optional(),
          })
          .allowUnknownKeys()
          .parse(params),
    }),
  )
  .extend(DeleteProposalCommentModalRouteFragment);

export const launchesRouteConfig = variantRouteConfig.extend(
  new RouteConfig({
    definition: '/launches/:launchId?',
    parseMatchParams: (params) =>
      myzod
        .object({
          launchId: myzod.string().optional(),
        })
        .allowUnknownKeys()
        .parse(params),
    parseSearchParams: (params) =>
      myzod
        .object({
          [Config.queryParameters.ComparisonId]: myzod.string().optional(),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);

export const legacyIntegrationsRouteConfig = variantRouteConfig.extend(
  new RouteConfig({
    definition: '/integrations',
  }),
);

export const implementingServicesRouteConfig = variantRouteConfig
  .extend(TimeRangeRouteFragment)
  .extend(
    new RouteConfig({
      definition: `/service-list`,
      parseSearchParams: (params) =>
        myzod
          .object({
            currentService: myzod.string().optional(),
          })
          .allowUnknownKeys()
          .parse(params),
    }),
  );

/**
 * Routing configuration for Clients L1
 */
export const clientsRouteConfig = variantRouteConfig
  .extend(TimeRangeRouteFragment)
  .extend(
    new RouteConfig({
      definition: '/clients',
      parseSearchParams: (params) =>
        myzod
          .object({
            clientName: myzod.string().nullable().optional(),
            clientVersion: myzod.string().nullable().optional(),
          })
          .allowUnknownKeys()
          .parse(params),
    }),
  );

function hasOwnProperty<X extends {}, Y extends PropertyKey>(
  obj: X,
  prop: Y,
): obj is X & Record<Y, unknown> {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

export const atlasExplorerRouteConfig = new RouteConfig({
  definition: '/directory',
  parseSearchParams: (params) =>
    myzod
      .object({
        graphRef: myzod.string().optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});

export const createExplorerUrlState = ({
  document,
  variables,
  headers,
  preflightOperationScript,
  postflightOperationScript,
  savedCollectionId,
  savedCollectionEntryId,
  sharedCollectionOriginVariantRef,
  includeCookies,
}: Partial<{
  document: string | undefined;
  variables: string | undefined;
  headers: string | undefined;
  preflightOperationScript: string | undefined;
  postflightOperationScript: string | undefined;
  savedCollectionId: string | undefined;
  savedCollectionEntryId: string | undefined;
  sharedCollectionOriginVariantRef: string | undefined;
  includeCookies: string | undefined;
}>) =>
  typeof document === 'string' ||
  typeof variables === 'string' ||
  typeof headers === 'string' ||
  typeof preflightOperationScript === 'string' ||
  typeof postflightOperationScript === 'string' ||
  typeof savedCollectionId === 'string' ||
  typeof savedCollectionEntryId === 'string' ||
  typeof includeCookies === 'string'
    ? LZString.compressToEncodedURIComponent(
        JSON.stringify({
          document,
          variables,
          headers,
          preflightOperationScript,
          postflightOperationScript,
          savedCollectionId,
          savedCollectionEntryId,
          sharedCollectionOriginVariantRef,
          includeCookies,
        }),
      )
    : undefined;

const ExplorerIntialValueRouteFragment = new RouteConfig({
  parseSearchParams: (queryStringParams: unknown) => {
    const explorerURLState =
      typeof queryStringParams === 'object' &&
      queryStringParams &&
      hasOwnProperty(queryStringParams, 'explorerURLState') &&
      typeof queryStringParams.explorerURLState === 'string'
        ? JSON.parse(
            LZString.decompressFromEncodedURIComponent(
              queryStringParams.explorerURLState,
            ) || '{}',
          )
        : null;

    return myzod
      .object({
        document: myzod.string().optional(),
        searchQuery: myzod.string().optional(),
        variables: myzod.string().optional(),
        headers: myzod.string().optional(),
        preflightOperationScript: myzod.string().optional(),
        postflightOperationScript: myzod.string().optional(),
        [Config.queryParameters.Referrer]: myzod.string().optional(),
        savedCollectionId: myzod.string().optional(),
        savedCollectionEntryId: myzod.string().optional(),
        sharedCollectionOriginVariantRef: myzod.string().optional(),
        includeCookies: myzod.literals('true', 'false').optional(),
      })
      .allowUnknownKeys()
      .parse({
        ...(typeof queryStringParams === 'object' && queryStringParams),
        ...(typeof explorerURLState === 'object' && explorerURLState),
      });
  },
  locationSearch({
    document,
    variables,
    headers,
    preflightOperationScript,
    postflightOperationScript,
    savedCollectionId,
    savedCollectionEntryId,
    sharedCollectionOriginVariantRef,
    includeCookies,
    ...params
  }) {
    return stringify({
      explorerURLState: createExplorerUrlState({
        document,
        variables,
        headers,
        preflightOperationScript,
        postflightOperationScript,
        savedCollectionId,
        savedCollectionEntryId,
        sharedCollectionOriginVariantRef,
        includeCookies,
      }),
      ...this.parseSearchParams(params as ParsedQuery<string>),
    });
  },
});

const EmbedExplorerModalRouteFragment = new RouteConfig({
  parseState: (params) =>
    myzod
      .object({
        embedModalTabState: myzod
          .object({
            document: myzod.string(),
            variables: myzod.string(),
            headers: myzod.string(),
            includeCookies: myzod.literals('true', 'false').optional(),
          })
          .optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});

const SupergraphEndpointSuccessNudgeRouteFragment = new RouteConfig({
  parseState: (params) =>
    myzod
      .object({
        showSupergraphEndpointSuccessNudge: myzod
          .literals('true', 'false')
          .optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});

const OperationCollectionsRouteFragment = new RouteConfig({
  parseSearchParams: (params) =>
    myzod
      .object({
        collectionId: myzod.string().optional(),
        focusCollectionId: myzod.string().optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});

const baseExplorerRouteConfig = new RouteConfig({
  definition: '/explorer',
})
  .extend(ExplorerIntialValueRouteFragment)
  .extend(EmbedExplorerModalRouteFragment)
  .extend(OperationCollectionsRouteFragment)
  .extend(SupergraphEndpointSuccessNudgeRouteFragment);
export const explorerRouteConfig = variantRouteConfig.extend(
  baseExplorerRouteConfig,
);

export const insightsFilterRouteFragment = TimeRangeRouteFragment.extend(
  new RouteConfig({
    parseSearchParams: (params) =>
      myzod
        .object({
          [Config.queryParameters.SelectedQueryId]: myzod.string().optional(),
          [Config.queryParameters.SelectedQueryName]: myzod
            .string()
            .nullable()
            .optional(),
          [Config.queryParameters.QueryListMetricSort]: myzod
            .literals(
              ...(Object.keys(
                metricConfig.allOperationsMetrics,
              ) as NormalizedMetricsField[]),
            )
            .optional(),
          [Config.queryParameters.QueryListMetricFilter]: myzod
            .literals(
              ...(Object.keys(
                metricConfig.allOperationsFilters,
              ) as NormalizedMetricsFilterField[]),
            )
            .optional(),
          [Config.queryParameters.OperationListSearchFilter]: myzod
            .string()
            .optional(),
          [Config.queryParameters.OperationListSortDirection]: myzod
            .literals('asc', 'desc')
            .optional(),
          [Config.queryParameters.OperationListMetricSort]: myzod
            .literals(...Object.keys(metricConfig.allOperationsMetrics))
            .optional(),
          [Config.queryParameters.OperationListMetricFilter]: myzod
            .literals(...Object.keys(metricConfig.allOperationsFilters))
            .optional(),
          [Config.queryParameters.OperationListMetricFilters]: myzod
            .array(
              myzod.literals(
                ...(Object.keys(
                  metricConfig.newAllOperationsFilters,
                ) as NewNormalizedMetricsFilterField[]),
              ),
            )
            .coerce((value) => [value as NewNormalizedMetricsFilterField])
            .optional(),
          [Config.queryParameters.OperationListBeforeCursor]: myzod
            .string()
            .optional(),
          [Config.queryParameters.OperationListAfterCursor]: myzod
            .string()
            .optional(),
          [Config.queryParameters.OperationListPage]: myzod
            .number()
            .coerce()
            .optional(),
          [Config.queryParameters.OperationListPaginationDirection]: myzod
            .literals('first', 'last')
            .optional(),
          [Config.queryParameters.FieldListSearchFilter]: myzod
            .string()
            .optional(),
          [Config.queryParameters.FieldListMetricSort]: myzod
            .literals(
              ...(Object.keys(
                metricConfig.allFieldsMetrics,
              ) as NormalizedFieldsMetricsField[]),
            )
            .optional(),
          [Config.queryParameters.FieldListMetricFilter]: myzod
            .union([
              myzod.literal('none'), // unfiltered / show everything
              myzod
                .array(
                  myzod.literals(
                    ...(Object.keys(
                      metricConfig.allFieldsFilters,
                    ) as NormalizedFieldsMetricsFilterField[]),
                  ),
                )
                .coerce(
                  (value) => [value] as NormalizedFieldsMetricsFilterField[],
                ),
            ])
            .optional(),
          [Config.queryParameters.FieldListSortDirection]: myzod
            .literals('asc', 'desc')
            .optional(),
          [Config.queryParameters.FieldListBeforeCursor]: myzod
            .string()
            .optional(),
          [Config.queryParameters.FieldListAfterCursor]: myzod
            .string()
            .optional(),
          [Config.queryParameters.FieldListPage]: myzod
            .number()
            .coerce()
            .optional(),
          [Config.queryParameters.FieldListPaginationDirection]: myzod
            .literals('first', 'last')
            .optional(),
          [Config.queryParameters.FastModeRange]: myzod
            .literals('lastMonth', 'lastThreeMonths')
            .optional(),
        })
        .allowUnknownKeys()
        .parse(params),
    parseState: (params) =>
      myzod
        .object({
          clients: myzod
            .union([
              myzod.record(
                myzod.union([myzod.array(myzod.string()), myzod.literals('*')]),
              ),
              myzod.null(),
            ])
            .optional(),
          previousFieldName: myzod.string().optional(),
          previousFieldType: myzod.string().optional(),
          previousInputFieldNamedAttribute: myzod.string().optional(),
          previousInputFieldNamedType: myzod.string().optional(),
          previousEnumNamedAttribute: myzod.string().optional(),
          previousEnumNamedType: myzod.string().optional(),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);

/**
 *  These are shared between insightsRouteConfig and fieldRouteConfigs
 *  so that we can preserve them across navigations, but we should consider
 *  just merging these 2 route configs now by moving typeName and fieldName
 *  to search params.
 */
const sharedInsightsSearchParams = {
  [Config.queryParameters.ActiveReportPaneTab]: myzod
    .literals('usage', 'errors', 'traces', 'operation', 'performance')
    .optional(),
  [Config.queryParameters.SelectedTraceId]: myzod.string().optional(),
  [Config.queryParameters.SelectedDurationBucket]: myzod
    .string()
    .nullable()
    .optional(),
  [Config.queryParameters.InsightsTab]: myzod
    .literals(
      'fields',
      'operations',
      'inputFields',
      'enumValues',
      'objectFields',
    )
    .optional(),
  [Config.queryParameters.CoordinateKind]: myzod
    .enum(GraphQLTypes.CoordinateKind)
    .optional(),
};

export const fieldRouteConfig = variantRouteConfig
  .extend(insightsFilterRouteFragment)
  .extend(
    new RouteConfig({
      definition: '/insights/schema/:typeName.:fieldName',
      parseMatchParams: (params) =>
        myzod
          .object({
            typeName: myzod.string(),
            fieldName: myzod.string(),
          })
          .allowUnknownKeys()
          .parse(params),
      parseSearchParams: (params) =>
        myzod
          .object({
            ...sharedInsightsSearchParams,
          })
          .allowUnknownKeys()
          .parse(params),
    }),
  );

export const coordinateNotFoundRouteConfig = variantRouteConfig
  .extend(insightsFilterRouteFragment)
  .extend(
    new RouteConfig({
      definition: '/insights/schema/notFound',
      parseSearchParams: (params) =>
        myzod
          .object({
            ...sharedInsightsSearchParams,
          })
          .allowUnknownKeys()
          .parse(params),
    }),
  );

export const insightsRouteConfig = variantRouteConfig
  .extend(insightsFilterRouteFragment)
  .extend(
    new RouteConfig({
      definition: '/insights',
      parseSearchParams: (params) =>
        myzod
          .object({
            ...sharedInsightsSearchParams,
            [Config.queryParameters.Overlay]: myzod
              .literals('operation-details')
              .optional(),
          })
          .allowUnknownKeys()
          .parse(params),
    }),
  );

const referenceCategorySlugSchema = myzod.literals(
  'objects',
  'scalars',
  'interfaces',
  'unions',
  'enums',
  'inputs',
  'directives',
);
export type ReferenceCategorySlug = myzod.Infer<
  typeof referenceCategorySlugSchema
>;

const graphSettingsSlugSchema = myzod.literals(
  'general',
  'permissions',
  'api-keys',
  'reporting',
  'variants',
  'proposals',
  'checks',
  'checks_custom_checks',
  'checks_operations',
  'checks_linter',
  'checks_proposals',
);
export type GraphSettingsSlug = myzod.Infer<typeof graphSettingsSlugSchema>;

const variantSettingsSlugSchema = myzod.literals(
  'general',
  'explorer',
  'contracts',
  'routing',
  'checks',
  'checks_custom_checks',
  'checks_operations',
  'checks_linter',
  'checks_proposals',
);
export type VariantSettingsSlug = myzod.Infer<typeof variantSettingsSlugSchema>;

/**
 * Routing configuration for Dedicated GraphOS Settings L1 tab and page
 */
const cloudSettingsSlugSchema = myzod.literals(
  'general',
  'endpoint-urls',
  'configuration',
);
export type CloudSettingsSlug = myzod.Infer<typeof cloudSettingsSlugSchema>;

const baseSchemaRouteConfig = new RouteConfig({
  definition: '/schema',
  parseSearchParams: (params) =>
    myzod
      .object({
        federationIntro: myzod
          .literals(
            'intro',
            'graph-label',
            'subgraph-of-origin',
            'explore-schema',
          )
          .optional(),
        publishUpdate: myzod.literal('true').optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});
export const schemaRouteConfig = variantRouteConfig.extend(
  baseSchemaRouteConfig,
);

export const proposalSchemaRouteConfig = proposalRouteConfig.extend(
  baseSchemaRouteConfig,
);

export type SDLHash = {
  graphqlTypeName?: string;
  fieldName?: string;
  argumentName?: string;
  directiveName?: string;
};

export const baseSdlRouteConfig = new RouteConfig({
  definition: `/sdl`,
}).extend(
  new RouteConfig({
    parseSearchParams: (params) =>
      myzod
        .object({
          selectedSchema: myzod.string().optional(),
        })
        .allowUnknownKeys()
        .parse(params),
    parseHash: (hash): SDLHash => {
      const parsedHash = hash?.split('.');
      const [firstElement, secondElement, thirdElement] = parsedHash || [];

      if (firstElement?.includes('@')) {
        return {
          directiveName: firstElement.substring(1),
          argumentName: secondElement,
        };
      }
      return {
        graphqlTypeName: firstElement,
        fieldName: secondElement,
        argumentName: thirdElement,
      };
    },
    locationHash: ({
      graphqlTypeName,
      fieldName,
      argumentName,
      directiveName,
    }: SDLHash) =>
      directiveName
        ? `@${directiveName}${argumentName ? `.${argumentName}` : ''}`
        : graphqlTypeName
        ? `${graphqlTypeName}${
            fieldName
              ? argumentName
                ? `.${fieldName}.${argumentName}`
                : `.${fieldName}`
              : ''
          }`
        : '',
  }),
);

export function isTagHighlight(
  highlight: Parameters<
    typeof visualizationRouteConfig['path']
  >[0]['highlight'],
): highlight is `tag-${string}` {
  return !!highlight?.match(/tag-.*/);
}
export function isDirectiveHighlight(
  highlight: Parameters<
    typeof visualizationRouteConfig['path']
  >[0]['highlight'],
): highlight is `directive-${string}` {
  return !!highlight?.match(/directive-.*/);
}
const stableCastArray = _.memoize(_.castArray, (arr: string[] | string) =>
  typeof arr === 'string' ? arr : arr.join(','),
);
const baseVisualizationRouteConfig = new RouteConfig({
  definition: `/visualization`,
  parseSearchParams: (params) => {
    const highlightLiterals = myzod.literals(
      'Errors',
      'Latency',
      'Usage',
      'Entities',
      'Unused',
    );

    const { subgraphs, tags, highlight, ...parsedParams } = myzod
      .object({
        focused: myzod.string().optional(),
        rootType: myzod.string().optional(),
        subgraphs: myzod
          .union([myzod.array(myzod.string()), myzod.string()])
          .optional(),
        tags: myzod
          .union([myzod.array(myzod.string()), myzod.string()])
          .optional(),
        collapseTypes: myzod.literals('true', 'false').optional(),
        hideUnusedFields: myzod.literals('true', 'false').optional(),
        hideDeprecated: myzod.literals('true', 'false').optional(),
        hideInputTypes: myzod.literals('true', 'false').optional(),
        hideEnumTypes: myzod.literals('true', 'false').optional(),
        hideLeafNodes: myzod.literals('true', 'false').optional(),
        highlight: myzod
          .union([
            highlightLiterals,
            myzod.string({ pattern: /(tag|directive)-.*/ }),
          ])
          .optional(),
        showDetails: myzod.literals('true', 'false').optional(),
      })
      .allowUnknownKeys()
      .parse(params);
    return {
      ...(highlight
        ? {
            highlight: highlight as
              | ReturnType<typeof highlightLiterals.parse>
              | `${'tag' | 'directive'}-${string}`,
          }
        : {}),
      ...parsedParams,
      ...(subgraphs !== undefined
        ? { subgraphs: stableCastArray(subgraphs) }
        : {}),
      ...(tags !== undefined ? { tags: stableCastArray(tags) } : {}),
    };
  },
}).extend(TimeRangeRouteFragment);
export const sdlRouteConfig = schemaRouteConfig.extend(baseSdlRouteConfig);
export const visualizationRouteConfig = schemaRouteConfig.extend(
  baseVisualizationRouteConfig,
);

export const legacyDocumentationSchemaRouteConfig = schemaRouteConfig.extend(
  new RouteConfig({
    definition: `/types/:category?/:graphqlTypeName?/:linkedGraphqlEntity?`,
    parseMatchParams: (params) =>
      myzod
        .object({
          category: referenceCategorySlugSchema.optional(),
          graphqlTypeName: myzod.string().optional(),
          linkedGraphqlEntity: myzod.string().optional(),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);

export const preHashLinkSchemaRouteConfig = schemaRouteConfig.extend(
  new RouteConfig({
    definition: `/reference/:category/:graphqlTypeName/:linkedGraphqlEntity`,
    parseMatchParams: (params) =>
      myzod
        .object({
          category: referenceCategorySlugSchema,
          graphqlTypeName: myzod.string(),
          linkedGraphqlEntity: myzod.string(),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);

type ReferenceHashParams = {
  // this is the field name or type name in the # of the referenceRoute,
  // which anchor links to said field (in a TypeFieldList) or type (in a TypeList)
  // TypeFieldList is the page shown when /graphqlTypeName exists
  linkedGraphqlEntity?: string;
};
const baseReferenceRouteConfig = new RouteConfig({
  definition: `/reference/:category?/:graphqlTypeName?`,
  parseMatchParams: (params) =>
    myzod
      .object({
        category: referenceCategorySlugSchema.optional(),
        graphqlTypeName: myzod.string().optional(),
      })
      .allowUnknownKeys()
      .parse(params),
  parseSearchParams: (params) =>
    myzod
      .object({
        query: myzod.string().optional(),
      })
      .allowUnknownKeys()
      .parse(params),
  parseHash: (hash) => {
    return hash.length ? { linkedGraphqlEntity: hash } : {};
  },
  locationHash: ({ linkedGraphqlEntity }: ReferenceHashParams) =>
    linkedGraphqlEntity ?? '',
});
export const referenceRouteConfig = schemaRouteConfig.extend(
  baseReferenceRouteConfig,
);
export const proposalReferenceRouteConfig = proposalSchemaRouteConfig.extend(
  baseReferenceRouteConfig,
);
export const proposalVisualizationRouteConfig =
  proposalSchemaRouteConfig.extend(baseVisualizationRouteConfig);

const ProposalsChecksFiltersFragment = new RouteConfig({
  parseSearchParams: (params) =>
    myzod
      .object({
        author: myzod
          .union([myzod.array(myzod.string()), myzod.string()])
          .optional(),
        createdBy: myzod
          .union([myzod.array(myzod.string()), myzod.string()])
          .optional(),
        status: myzod
          .enum(GraphQLTypes.CheckFilterInputStatusOption)
          .optional(),
        subgraph: myzod
          .union([myzod.array(myzod.string()), myzod.string()])
          .optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});

const ChecksFiltersFragment = new RouteConfig({
  parseSearchParams: (params) =>
    myzod
      .object({
        branch: myzod
          .union([myzod.array(myzod.string()), myzod.string()])
          .optional(),
        variants: myzod
          .union([myzod.array(myzod.string()), myzod.string()])
          .optional(),
      })
      .allowUnknownKeys()
      .parse(params),
}).extend(ProposalsChecksFiltersFragment);

// We share ChecksFiltersFragment for a number of separate routes. Add this so
// we can use `locationFrom` in components rendered on a few of these routes.
export const variantRouteConfigWithChecksFilters = variantRouteConfig.extend(
  ChecksFiltersFragment,
);

/**
 * Routing configuration for Checks L1
 */

const checksL3SlugSchema = myzod.literals('config', 'recent');
export type ChecksL3Slug = myzod.Infer<typeof checksL3SlugSchema>;

export const checksRouteConfig = variantRouteConfig.extend(
  new RouteConfig({
    definition: '/checks/:view(graph|variant)',
    parseMatchParams: (params) =>
      myzod
        .object({
          view: myzod.literals('graph', 'variant'),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);

export const recentChecksRouteConfig = checksRouteConfig
  .extend(
    new RouteConfig({
      definition: '/recent',
    }),
  )
  .extend(ChecksFiltersFragment);

const legacyChecksConfigL3SlugSchema = myzod.literals(
  'operations',
  'linter',
  'proposals',
  'custom_checks',
);
export type LegacyChecksConfigL3Slug = myzod.Infer<
  typeof legacyChecksConfigL3SlugSchema
>;
export const legacyChecksConfigRouteConfig = checksRouteConfig.extend(
  new RouteConfig({
    definition: '/config/:tab(operations|linter|proposals|custom_checks)?',
    parseMatchParams: (params) =>
      myzod
        .object({ tab: legacyChecksConfigL3SlugSchema.optional() })
        .allowUnknownKeys()
        .parse(params),
  }),
);

export const legacyChecksRouteConfig =
  variantRouteConfigWithChecksFilters.extend(
    new RouteConfig({
      definition: '/checks/:checkId?',
      parseMatchParams: (params) =>
        myzod
          .object({
            checkId: myzod.string().optional(),
          })
          .allowUnknownKeys()
          .parse(params),
      parseSearchParams: (params) =>
        myzod
          .object({
            schemaTagId: myzod.string().optional(),
            query: myzod.string().optional(),
            tab: myzod.literals('check', 'config', 'list').optional(),
          })
          .allowUnknownKeys()
          .parse(params),
    }),
  );

const OperationsCheckDetailsFragment = new RouteConfig({
  definition: '/operationsCheck/:taskId',
  parseMatchParams: (params) =>
    myzod
      .object({
        taskId: myzod.string(),
      })
      .allowUnknownKeys()
      .parse(params),
  parseSearchParams: (params) =>
    myzod
      .object({
        query: myzod.string().optional(),
        [Config.queryParameters.Overlay]: myzod
          .literals('operation-details')
          .optional(),
        page: myzod.number().coerce().optional(),
        search: myzod.string().optional(),
        statusFilter: myzod
          .array(
            myzod.literals(
              'broken',
              'potentially-affected',
              'marked-as-safe',
              'ignored',
              'unaffected',
            ),
          )
          .coerce((value) => [value as OperationStatus])
          .optional(),
      })
      .allowUnknownKeys()
      .parse(params),
  parseState: () => ({}),
  parseHash: () => ({}),
});

export const operationsCheckDetailsRoute =
  variantRouteConfigWithChecksFilters.extend(OperationsCheckDetailsFragment);

const BuildCheckDetailsFragment = new RouteConfig({
  definition: '/composition/:taskId',
  parseMatchParams: (params) =>
    myzod
      .object({
        taskId: myzod.string(),
      })
      .allowUnknownKeys()
      .parse(params),
  parseState: () => ({}),
  parseHash: () => ({}),
});

export const buildCheckDetailsRoute =
  variantRouteConfigWithChecksFilters.extend(BuildCheckDetailsFragment);

const DownstreamCheckDetailsFragment = new RouteConfig({
  definition: '/downstream/:taskId',
  parseMatchParams: (params) =>
    myzod
      .object({
        taskId: myzod.string(),
      })
      .allowUnknownKeys()
      .parse(params),
  parseState: () => ({}),
  parseHash: () => ({}),
});

export const downstreamCheckDetailsRoute =
  variantRouteConfigWithChecksFilters.extend(DownstreamCheckDetailsFragment);

const FilterBuildCheckDetailsFragment = new RouteConfig({
  definition: '/filter/:taskId',
  parseMatchParams: (params) =>
    myzod
      .object({
        taskId: myzod.string(),
      })
      .allowUnknownKeys()
      .parse(params),
  parseState: () => ({}),
  parseHash: () => ({}),
});

export const filterBuildCheckDetailsRoute =
  variantRouteConfigWithChecksFilters.extend(FilterBuildCheckDetailsFragment);

const LintCheckDetailsFragment = new RouteConfig({
  definition: '/lint/:taskId',
  parseMatchParams: (params) =>
    myzod
      .object({
        taskId: myzod.string(),
      })
      .allowUnknownKeys()
      .parse(params),
  parseSearchParams: (params) =>
    myzod
      .object({
        resultView: myzod.literals('list', 'diff').optional(),
      })
      .allowUnknownKeys()
      .parse(params),
  parseState: (params) =>
    myzod
      .object({
        ignoreRuleViolationModalState: myzod
          .object({
            ruleName: myzod.string(),
            coordinate: myzod.string(),
            subgraphName: myzod.string().optional(),
          })
          .optional(),
      })
      .allowUnknownKeys()
      .parse(params),
  parseHash: () => ({}),
});

export const lintCheckDetailsRoute = variantRouteConfigWithChecksFilters.extend(
  LintCheckDetailsFragment,
);

const CustomCheckDetailsFragment = new RouteConfig({
  definition: '/custom/:taskId',
  parseMatchParams: (params) =>
    myzod
      .object({
        taskId: myzod.string(),
      })
      .allowUnknownKeys()
      .parse(params),
  parseSearchParams: (params) =>
    myzod
      .object({
        resultView: myzod.literals('list', 'diff').optional(),
      })
      .allowUnknownKeys()
      .parse(params),
  parseState: () => ({}),
  parseHash: () => ({}),
});

export const customCheckDetailsRoute =
  variantRouteConfigWithChecksFilters.extend(CustomCheckDetailsFragment);

type ProposalCheckDetailsHashParams = {
  // this is the subgraph name that contains the coordinate (for multi-subgraph checks)
  linkedSubgraphName?: string;
  // this is the coordinate name that we should reveal in the drawer
  linkedCoordinateName?: string;
  isDescription?: boolean;
};

const ProposalCheckDetailsFragment = new RouteConfig({
  definition: '/proposal/:taskId',
  parseMatchParams: (params) =>
    myzod
      .object({
        taskId: myzod.string(),
      })
      .allowUnknownKeys()
      .parse(params),
  parseState: () => ({}),
  parseHash: (hash) => {
    const [linkedSubgraphName, ...rest] = hash.split('.');
    const restJoined = rest.join('.');
    const descriptionMarkerIndex = restJoined.lastIndexOf('~');
    const linkedCoordinateName =
      descriptionMarkerIndex === -1
        ? restJoined
        : restJoined.substring(0, descriptionMarkerIndex);
    const isDescription =
      restJoined.substring(descriptionMarkerIndex + 1) === 'description';
    return linkedSubgraphName.length
      ? {
          isDescription,
          linkedSubgraphName,
          linkedCoordinateName,
        }
      : {};
  },
  locationHash: ({
    linkedSubgraphName,
    linkedCoordinateName,
    isDescription,
  }: ProposalCheckDetailsHashParams) =>
    linkedSubgraphName && linkedCoordinateName
      ? `${linkedSubgraphName}.${linkedCoordinateName}${
          isDescription ? '~description' : ''
        }`
      : '',
});

export const proposalCheckDetailsRoute =
  variantRouteConfigWithChecksFilters.extend(ProposalCheckDetailsFragment);

export const proposalChecksRouteConfig = proposalRouteConfig.extend(
  new RouteConfig({
    definition: '/checks',
  }),
);

export const proposalChecksListRouteConfig = proposalChecksRouteConfig
  .extend(
    new RouteConfig({
      parseSearchParams: (params) =>
        myzod
          .object({
            page: myzod.number().coerce().optional(),
            shouldHighlightLatest: myzod.string().optional(),
          })
          .allowUnknownKeys()
          .parse(params),
    }),
  )
  .extend(ProposalsChecksFiltersFragment);

export const proposalOperationsCheckDetailsRoute =
  proposalChecksRouteConfig.extend(OperationsCheckDetailsFragment);
export const proposalBuildCheckDetailsRoute = proposalChecksRouteConfig.extend(
  BuildCheckDetailsFragment,
);
export const proposalDownstreamCheckDetailsRoute =
  proposalChecksRouteConfig.extend(DownstreamCheckDetailsFragment);
export const proposalFilterBuildCheckDetailsRoute =
  proposalChecksRouteConfig.extend(FilterBuildCheckDetailsFragment);
export const proposalLintCheckDetailsRoute = proposalChecksRouteConfig.extend(
  LintCheckDetailsFragment,
);
export const proposalCustomCheckDetailsRoute = proposalChecksRouteConfig.extend(
  CustomCheckDetailsFragment,
);
export const proposalProposalCheckDetailsRoute =
  proposalChecksRouteConfig.extend(ProposalCheckDetailsFragment);

export const changelogRouteConfig = variantRouteConfig.extend(
  new RouteConfig({
    definition: '/changelog',
  }),
);

export const changelogSingleChangeRouteConfig = changelogRouteConfig.extend(
  new RouteConfig({
    definition: '/version/:version',
    parseMatchParams: (params) =>
      myzod
        .object({
          version: myzod.string(),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);

// TODO: update settings route config to have a route config for each tab
/*
type SettingsTab = 'general' | 'notifications' | 'access';
... myzod.literals<SettingsTab, [SettingsTab, ...SettingsTab[]]>
*/
export const graphSettingsRouteConfig = variantRouteConfig.extend(
  new RouteConfig({
    definition: '/settings/graph/:tab?',
    parseMatchParams: (params) =>
      myzod
        .object({ tab: graphSettingsSlugSchema.optional() })
        .allowUnknownKeys()
        .parse(params),
    parseState: (params) =>
      myzod
        .object({
          highlightVariantState: myzod
            .object({
              variant: myzod.string(),
            })
            .optional(),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);

export const variantSettingsRouteConfig = variantRouteConfig.extend(
  new RouteConfig({
    definition: '/settings/variant/:tab?',
    parseMatchParams: (params) =>
      myzod
        .object({
          tab: variantSettingsSlugSchema.optional(),
        })
        .allowUnknownKeys()
        .parse(params),
    parseState: (params) =>
      myzod
        .object({
          deleteSecretModalState: myzod
            .object({
              secretName: myzod.string(),
            })
            .optional(),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);

/**
 * RouteConfig for the Dedicated GraphOS settings page
 */
export const cloudSettingsRouteConfig = variantRouteConfig.extend(
  new RouteConfig({
    definition: '/cloud/:tab?',
    parseMatchParams: (params) =>
      myzod
        .object({
          tab: cloudSettingsSlugSchema.optional(),
        })
        .allowUnknownKeys()
        .parse(params),
    parseState: (params) =>
      myzod
        .object({
          deleteSecretModalState: myzod
            .object({
              secretName: myzod.string(),
            })
            .optional(),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);

/**
 * RouteConfig for the top-level `/graphs`
 */
export const sandboxGraphRouteConfig = new RouteConfig<
  {},
  {
    endpoint?: string;
    subscriptionEndpoint?: string;
  },
  unknown,
  unknown
>({
  definition: '/sandbox',
  parseSearchParams: (params) =>
    myzod
      .object({
        endpoint: myzod.string().optional(),
        subscriptionEndpoint: myzod.string().optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});

export const sandboxExplorerRouteConfig = sandboxGraphRouteConfig.extend(
  baseExplorerRouteConfig,
);

export const sandboxSchemaRouteConfig = sandboxGraphRouteConfig.extend(
  baseSchemaRouteConfig,
);

export const sandboxSdlRouteConfig =
  sandboxSchemaRouteConfig.extend(baseSdlRouteConfig);

export const sandboxVisualizationRouteConfig = sandboxSchemaRouteConfig.extend(
  baseVisualizationRouteConfig,
);

export const sandboxReferenceRouteConfig = sandboxSchemaRouteConfig.extend(
  baseReferenceRouteConfig,
);

export const sandboxDiffRouteConfig = sandboxGraphRouteConfig.extend(
  new RouteConfig({
    definition: '/diff/',
  }),
);

export const sandboxCheckRouteConfig = sandboxGraphRouteConfig.extend(
  new RouteConfig({
    definition: '/checks/',
    parseState: (params) =>
      myzod
        .object({
          graphRefToAutoCheck: myzod
            .object({
              graphId: myzod.string(),
              graphVariant: myzod.string(),
              subgraphName: myzod.string().optional(),
            })
            .optional(),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);

const EmbedExplorerDefaultRouteFragment = new RouteConfig({
  parseSearchParams: (params) =>
    myzod
      .object({
        defaultDocument: myzod.string().optional(),
        defaultHeaders: myzod.string().optional(),
        defaultVariables: myzod.string().optional(),
        defaultCollectionEntryId: myzod.string().optional(),
        defaultCollectionId: myzod.string().optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});

export type EmbedDefaultRouteFragmentType = typeof EmbedDefaultRouteFragment;
const EmbedDefaultRouteFragment = new RouteConfig({
  parseSearchParams: (params) =>
    myzod
      .object({
        version: myzod.string().optional(),
        runTelemetry: myzod.literals('true', 'false').optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});

export type EmbeddedSandboxRouteFragmentType =
  typeof EmbeddedSandboxRouteFragment;
const EmbeddedSandboxRouteFragment = new RouteConfig({
  parseSearchParams: (params) =>
    myzod
      .object({
        initialRequestQueryPlan: myzod.literals('true', 'false').optional(),
        initialRequestConnectorsDebugging: myzod
          .literals('true', 'false')
          .optional(),
        shouldDefaultAutoupdateSchema: myzod
          .literals('true', 'false')
          .optional(),
        endpointIsEditable: myzod.literals('true', 'false').optional(),
        sharedHeaders: myzod.string().optional(),
        hideCookieToggle: myzod.literals('true', 'false').optional(),
        defaultIncludeCookies: myzod.literals('true', 'false').optional(),
        sendOperationHeadersInIntrospection: myzod
          .literals('true', 'false')
          .optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});

export type EmbeddedExplorerRouteFragmentType =
  typeof EmbeddedExplorerRouteFragment;
const EmbeddedExplorerRouteFragment = new RouteConfig({
  // XXX(Chang): this parseSearchParams is being called a ridiculous number of times on each variant page (e.g. schema documentation), almost always with an argument of -- {}
  // memoizing the function as a hack to avoid performance slowdowns, but this is just covering up some rot we should excise from RouteConfigs
  // https://apollographql.atlassian.net/browse/NEBULA-3181
  parseSearchParams: _.memoize(
    (params) => {
      return myzod
        .object({
          shouldPersistState: myzod.literals('true', 'false').optional(),
          docsPanelState: myzod.literals('open', 'closed').optional(),
          showHeadersAndEnvVars: myzod.literals('true', 'false').optional(),
          theme: myzod.literals('light', 'dark').optional(),
          graphRef: myzod.string().optional(),
          shouldShowGlobalHeader: myzod.literals('true', 'false').optional(),
          parentSupportsSubscriptions: myzod
            .literals('true', 'false')
            .optional(),
        })
        .allowUnknownKeys()
        .parse(params);
    },
    (...args) => {
      return JSON.stringify(args);
    },
  ),
});

// This is the route config for root path of the embedded explorer subdomain explorer.embed.apollographql.com
export const embeddableExplorerRouteConfig = new RouteConfig({
  definition: '',
})
  .extend(EmbeddedExplorerRouteFragment)
  .extend(EmbedDefaultRouteFragment)
  .extend(EmbedExplorerDefaultRouteFragment)
  .extend(ExplorerIntialValueRouteFragment)
  .extend(OperationCollectionsRouteFragment);

// This is the route config for root path of the embedded sandbox subdomain sandbox.embed.apollographql.com
export const embeddableSandboxRouteConfig = sandboxGraphRouteConfig
  .extend(EmbedDefaultRouteFragment)
  .extend(EmbedExplorerDefaultRouteFragment)
  .extend(EmbeddedSandboxRouteFragment);

export const embeddableSandboxExplorerRouteConfig = embeddableSandboxRouteConfig
  .extend(baseExplorerRouteConfig)
  .extend(ExplorerIntialValueRouteFragment)
  .extend(OperationCollectionsRouteFragment);

// TODO(cleanup): This should be part of the config it extends
export const contractsConfigRoute = variantSettingsRouteConfig.extend(
  new RouteConfig({
    parseSearchParams: (params) =>
      myzod
        .object({
          sourceVariant: myzod.string().optional(),
          overlay: myzod.literals(Config.modals.configureContractWizard),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);

export const editContractConfigRoute = variantRouteConfig.extend(
  new RouteConfig({
    parseSearchParams: (params) =>
      myzod
        .object({
          sourceVariant: myzod.string().optional(),
          contractVariant: myzod.string().optional(),
          overlay: myzod.literals(Config.modals.editContractConfigWizard),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);

export const viewContractDetailsRoute = variantRouteConfig.extend(
  new RouteConfig({
    parseSearchParams: (params) =>
      myzod
        .object({
          contractVariant: myzod.string(),
          overlay: myzod.literals(Config.modals.viewContractDetails),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);

/**
 * Routing Config for Subgraphs L1
 */

export const subgraphsRouteConfig = variantRouteConfig.extend(
  new RouteConfig({
    definition: '/subgraphs',
    parseSearchParams: (params) =>
      myzod
        .object({
          overlay: myzod
            .literals(
              Config.modals.deleteSubgraph,
              Config.modals.editSubgraphRoutingUrl,
              Config.modals.addSubgraph,
              Config.modals.roverAddSubgraph,
              Config.modals.roverAddVariant,
              Config.modals.reuploadSubgraph,
            )
            .optional(),
        })
        .allowUnknownKeys()
        .parse(params),
    parseState: (params) =>
      myzod
        .object({
          editRoutingUrlModalState: myzod
            .object({
              subgraphName: myzod.string(),
              routingUrl: myzod.string(),
            })
            .optional(),
          deleteSubgraphModalState: myzod
            .object({
              subgraphName: myzod.string(),
            })
            .optional(),
          highlightSubgraphState: myzod
            .object({
              subgraphName: myzod.string(),
            })
            .optional(),
          reuploadSubgraphModalState: myzod
            .object({
              subgraphName: myzod.string(),
            })
            .optional(),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);
