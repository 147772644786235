import { ApolloError, gql, useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { GraphQLError } from 'graphql';

import { useDefaultPlan } from 'src/hooks/useDefaultPlan';
import { Analytics } from 'src/lib/analytics';
import { appLinkContext } from 'src/lib/apollo/link';
import {
  BillingPlanTier,
  NewOrgMutation,
  NewOrgMutationVariables,
} from 'src/lib/graphqlTypes/types';

import { FormData } from '../utils';

const newOrgMutation = gql<NewOrgMutation, NewOrgMutationVariables>`
  mutation NewOrgMutation(
    $id: ID!
    $companyUrl: String
    $organizationName: String
    $planId: String
  ) {
    newAccount(
      id: $id
      companyUrl: $companyUrl
      organizationName: $organizationName
      planId: $planId
    ) {
      id
      currentPlan {
        id
        name
        tier
      }
    }
  }
`;

export function hasAccountExistsErrorMsg(msg: string) {
  return new RegExp(/already exists/i).test(msg);
}

function isAccountExistsError(error: GraphQLError) {
  return hasAccountExistsErrorMsg(error.message);
}

export interface CreateOrgArgs {
  userId?: string;
  values: FormData;
  planId?: string;
}

export interface SuccessfulResponse {
  isSuccess: true;
  isUsageTier: boolean | undefined;
  orgId?: string;
}
export interface ErrorResponse {
  isSuccess?: false;
  error?: unknown | ApolloError;
  formErrors: Record<string, string> | undefined;
}

export type Result = Promise<SuccessfulResponse | ErrorResponse>;

export function useCreateOrg() {
  const [newAccount, { loading }] = useMutation(newOrgMutation, {
    context: appLinkContext({
      ignoreSentryReporting: [isAccountExistsError],
    }),
  });

  const defaultPlanId = useDefaultPlan();

  const createOrg = async ({
    userId,
    values,
    planId,
  }: CreateOrgArgs): Result => {
    let result;

    try {
      result = await newAccount({
        variables: {
          id: values.accountId,
          companyUrl: values.companyUrl || null,
          organizationName: values.accountName || null,
          planId: planId || defaultPlanId,
        },
      });
      if (result?.errors) {
        throw new Error(
          result.errors.map((e) => e.message).join('; ') ||
            'Unable to create new organization.',
        );
      }
      if (userId) {
        Analytics.track(userId, 'Org Created', {
          category: 'Onboarding',
        });
      }

      return {
        orgId: result.data?.newAccount?.id,
        isSuccess: true,
        isUsageTier:
          result.data?.newAccount?.currentPlan?.tier ===
          BillingPlanTier.USAGE_BASED,
      };
    } catch (error) {
      Sentry.captureException(`Unable to create account: ${error}`);
      return {
        isSuccess: false,
        error,
        formErrors: result?.errors?.reduce(
          (errs: Record<string, string>, cur) => {
            return { ...errs, [cur.name]: cur.message };
          },
          {},
        ),
      };
    }
  };

  return {
    createOrg,
    loading,
  };
}
