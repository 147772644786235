import { ReactiveVar, makeVar, useReactiveVar } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { captureException } from '@sentry/react';
import { memoize } from 'lodash';
import React from 'react';
import type { RouteProps } from 'react-router';

import { EmbeddableExplorerConfig } from 'src/app/embeddableExplorer/initialConfigValues';
import { EmbeddableSandboxConfig } from 'src/app/embeddableSandbox/initialConfigValues';
import type { SandboxConnectionSettings } from 'src/app/graph/explorerPage/connectionSettingsModal/sandboxConnectionSettingsModal/SandboxConnectionSettingsModal';
import {
  CollectionEntryIdState,
  CollectionEntryState,
} from 'src/app/graph/explorerPage/hooks/useExplorerState/useEditorTabState/collectionEntryState';
import { SavedOperationLocalStateEntry } from 'src/app/graph/explorerPage/hooks/useExplorerState/useEditorTabState/useSavedOperationLocalState';
import { HeaderEntry } from 'src/app/graph/explorerPage/hooks/useExplorerState/useHeadersManagerContext/shared';
import { GraphQLSubscriptionLibraryChoice } from 'src/app/graph/explorerPage/hooks/useExplorerState/useSubscriptions';
import { GraphRef } from 'src/app/graph/hooks/useGraphRef';
import { SandboxSelectorState } from 'src/app/graph/sandboxShared/SandboxGraphRefSelectors';
import { Config } from 'src/lib/config/config';
import { GraphQLTypes } from 'src/lib/graphqlTypes';
import { isMinLengthArray } from 'src/lib/isMinLengthArray';
import { localStorageWithMemoryFallback } from 'src/lib/localStorageWithMemoryFallback';

import type { FieldTimingsPercentile } from '../app/graph/explorerPage/explorerSettings/useExplorerSettings';
import type { ExplorerDocsTab } from '../app/graph/explorerPage/helpers/ExplorerDocsTab';
import type { OperationHistoryItem } from '../app/graph/explorerPage/hooks/useExplorerState/useOperationHistory';
import type { ThemeName } from '../components/themeProvider/ThemeProvider';

import type { TrackingEvent } from './useTrackCustomEvent';

type LocalStorageKey = keyof typeof initialValues;

export type PerGraphIdentifier<Value> = {
  [domain in string]?: { [id in string]?: Value };
};

export type PerTab<Value> = PerGraphIdentifier<{ [tabId in string]: Value }>;

export type PerKey<Value> = {
  [key: string]: Value;
};

export const perTabInitialValues = {
  // Display names aren't used yet, but its easier to migrate all at once
  tabbedDisplayNames: {} as PerTab<string | undefined>,
  savedOperationLocalState: {} as PerTab<
    SavedOperationLocalStateEntry | undefined
  >,
  tabbedOperationValues: {} as PerTab<string>,
  tabbedVariableValues: {} as PerTab<string>,
  tabbedHeaderDefinitions: {} as PerTab<Array<HeaderEntry>>,
  tabbedScriptValues: {} as PerTab<string>,
  tabbedPostflightOperationScriptValues: {} as PerTab<string>,
  tabbedCheckStateForRemoteHeaders: {} as PerTab<Record<string, boolean>>,
  tabbedMockedResponse: {} as PerTab<string>,
};

export const perGraphIdentifierInitialValues = {
  ...perTabInitialValues,
  explorerShowConnectorsDebugging: {} as PerGraphIdentifier<boolean>,
  explorerShowTraces: {} as PerGraphIdentifier<boolean>,
  explorerShowQueryPlan: {} as PerGraphIdentifier<boolean>,
  studioOperationHistoryPerVariant: {} as PerGraphIdentifier<
    Array<OperationHistoryItem>
  >,
  selectedTab: {} as PerGraphIdentifier<string | undefined>,
  openTabs: {} as PerGraphIdentifier<Array<string>>,
  studioVariantEnvironmentVariables: {} as PerGraphIdentifier<string>,
  hasConfirmedPublicUrl: {} as PerGraphIdentifier<{
    url?: string;
    subscriptionUrl?: string;
    preflightScript?: string;
  }>,
  localDefaultHeaders: {} as PerGraphIdentifier<Array<HeaderEntry>>,
  preflightScriptEnabled: {} as PerGraphIdentifier<boolean>,
  operationScriptEnabled: {} as PerGraphIdentifier<boolean>,
  sandboxPreflightScript: {} as PerGraphIdentifier<string | undefined>,
  graphqlSubscriptionProtocol:
    {} as PerGraphIdentifier<GraphQLSubscriptionLibraryChoice>,
  lastUpdatedOperationCollectionId: {} as PerGraphIdentifier<string>,
  embedDefaultTabState: {} as PerGraphIdentifier<
    CollectionEntryState | CollectionEntryIdState | undefined
  >,
  initialSupergraphCreationHeaders: {} as PerGraphIdentifier<
    Array<HeaderEntry>
  >,
  hasDismissedNoEntitiesBanner: {} as PerGraphIdentifier<boolean>,
  embeddableExplorerConfigOptions:
    {} as PerGraphIdentifier<EmbeddableExplorerConfig>,

  embeddableSandboxConfigOptions:
    {} as PerGraphIdentifier<EmbeddableSandboxConfig>,
};

export const perKeyInitialValues = {
  sandboxConnectionSettings: {} as PerKey<SandboxConnectionSettings>,
  hasMigratedSendCookiesFromEmbed: {} as PerKey<boolean>,
  lastAccessedVariant: {} as PerKey<string | null>,
  // #TabbedStorageMigration this is a temporary storage place for per graph
  // operations/variables
  temporaryPerGraphOperationInfo: {} as PerKey<{
    operationString?: string;
    variables?: string;
  }>,
  embedAuthenticationDetails: {} as PerKey<
    | {
        origin: string;
        inviteToken?: string;
        accountId?: string;
      }
    | undefined
  >,
  embedParentHref: {} as PerKey<string | undefined>,
  // Although it is technically unnecessary, user API token is split into 2
  // components using one-time pad for additional security. Individual
  // components cannot be used to determine the full token. Part is stored here
  // in the embed's local storage, the other part in the parent's local storage
  partialEmbedUserApiTokens: {} as PerKey<
    { id?: string; partialToken?: string } | undefined
  >,
  temporaryEmbedLocalStorageId: {} as PerKey<undefined | string>,
};

export const DEFAULT_LOCAL_EXPLORER_SETTINGS = {
  autoManageVariables: true,
  mockingResponses: false,
  responseHints: GraphQLTypes.ResponseHints.NONE,
  themeName: GraphQLTypes.ThemeName.LIGHT,
  tableMode: false,
};

// All values must have entry here to be used
export const initialValues = {
  ...perGraphIdentifierInitialValues,
  ...perKeyInitialValues,
  trackingConsent: 'should_prompt' as
    | 'consented'
    | 'dismissed'
    | 'should_prompt',
  sdlDownloadFormat: 'raw' as 'raw' | 'json',
  notifications: '',
  dismissedMaintenanceIds: [] as string[],
  changesReadHeuristic: 0,
  accountIsSynced: false,
  temporaryInternalGlobalThemeName: 'light' as ThemeName,
  sudo: false,
  variantSettingsL3Width: 275,
  graphSettingsL3Width: 275,
  redirectAfterLogin: null as NonNullable<RouteProps['location']> | null,
  utm: null as null | {
    utmSource?: string;
    utmMedium?: string;
    utmCampaign?: string;
    referrer?: string;
  },
  studioVariablesHeight: 135,
  studioSubscriptionHeight: window.innerHeight * 0.3,
  studioDocsOpen: true,
  /** value stored as percent */
  studioReferenceWidth: 0.3,
  studioReferenceOpen: true,
  /** value stored as percent */
  studioResponseWidth: 0.22,
  preflightSnippetsOpen: false,
  preflightSnippetWidth: 275,
  preflightOutputWidth: 275,
  docsTab: {} as Record<string, ExplorerDocsTab>,
  studioVariablesOpen: false,
  explorerSearchHistory: [] as Array<{ parent: string; field: string }>,
  mermaidQueryPlan: true,
  fieldTimingsPercentile: '0.95' as FieldTimingsPercentile,
  explorerLinkSettings: {
    includeVariables: false,
    includeHeaders: false,
    includePreflightOperationScript: false,
    includePostflightOperationScript: false,
    preferencesSaved: false,
  },
  localExplorerSettings: DEFAULT_LOCAL_EXPLORER_SETTINGS,
  // Deprecated values
  isTableMode: false,
  explorerManageVariablesSetting: null as boolean | null,
  isExplorerPreRequestScriptEnabled: null as boolean | null,
  responseHints: null as 'sample responses' | 'timings' | null,
  initialThemeName: null as ThemeName | null,
  schemaPageMenuWidth: Math.min(window.innerWidth * 0.2, 320),
  operationsPageMenuWidth: 425,
  sandboxUrl: 'http://localhost:4000',
  manualRefetch: false,
  navCollapsed: true,
  hideOfficeHoursInvitationToTrialAccounts: false,
  hasTouchedDarkModeToggle: false,
  hasSubmittedEmailToLocalAgentInterestForm: false,
  trackingEventsToBeSent: [] as (TrackingEvent & {
    dateInMilliseconds: number;
    offline: boolean;
  })[],
  sandboxDiffShouldWrapLines: false,
  sandboxCheckDiffSelectorState: undefined as SandboxSelectorState | undefined,
  lastPerformedSandboxCheck: undefined as
    | {
        checkID: string;
        graphRef: GraphRef;
        endpoint: string;
        dateInMilliseconds: number;
      }
    | undefined,
  hasDismissedSubgraphsInSandboxFeatureCallOut: false,
  hasDismissedOperationCollectionsFeatureCallOut: false,
  showReferencePageSearchToggles: true,
  explorerDocsFieldSortOrder: 'alphabeticalAscending' as
    | 'alphabeticalAscending'
    | 'alphabeticalDescending'
    | 'none',
  newToGraphOSStudioOpen: true,
  hasRunFirstQuery: false,
  hasDismissedReadMeNudge: false,
  hasRequestedFullSchemaLinting: false,
  oauth2RequestQueryParams: undefined as undefined | string,
  hasDismissedProposalUnchangedElementsWarning: false,
  newFedVersionToastDismissed: [] as Array<{
    graphRef: GraphRef;
    dismissedAt: number | undefined;
  }>,
  isInsightsL3Open: true,
  insightsL3Width: 420,
  showExpandButtonSignal: true,
  hasDismissedGraphVizAdvertisement: false,
  hasDismissedEditorCompositionPopover: false,
};

/**
 * Get a memoized reactive variable based on the local storage key
 * use writeToLocalStorage to update the value
 */
const getReactiveVar = memoize(
  <Key extends LocalStorageKey>(
    key: Key,
  ): ReactiveVar<typeof initialValues[Key]> => {
    try {
      const item = localStorageWithMemoryFallback.getItem(`engine:${key}`);
      return makeVar(
        item === null
          ? initialValues[key]
          : item === 'undefined'
          ? (undefined as typeof initialValues[Key])
          : (JSON.parse(item) as typeof initialValues[Key]),
      );
    } catch (error) {
      Sentry.captureException(error);
      return makeVar(initialValues[key]);
    }
  },
);

function confirmDialog(msg: string) {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-alert
    const confirmed = window.confirm(msg);

    return confirmed ? resolve(true) : reject();
  });
}

export function writeToLocalStorage<Key extends LocalStorageKey>(
  key: Key,
  value: React.SetStateAction<typeof initialValues[Key]>,
  // set to true to avoid pushing the write to the next tick via setTimeout
  immediately?: boolean,
) {
  const reactiveVariable = getReactiveVar(key);
  const nextValue =
    value instanceof Function ? value(reactiveVariable()) : value;
  reactiveVariable(nextValue);
  const performWrite = () => {
    try {
      localStorageWithMemoryFallback.setItem(
        `engine:${key}`,
        JSON.stringify(nextValue),
      );
    } catch (error) {
      if (
        typeof error === 'object' &&
        error &&
        'message' in error &&
        typeof error.message === 'string' &&
        error.message.includes(
          `Failed to execute 'setItem' on 'Storage': Setting the value of 'engine:studioOperationHistoryPerVariant'`,
        )
      ) {
        confirmDialog(
          `We have run out of local storage space 😔. Would you like to clear out Explorer's run history?`,
        )
          .then(() => {
            writeToLocalStorage('studioOperationHistoryPerVariant', {});
            window.location.reload(); // reload to trigger migrations to run again
          })
          // eslint-disable-next-line no-alert
          .catch(() => alert('Unable to clear local storage!'));
      }
      // A more advanced implementation would handle the error case
      Sentry.captureException({
        error,
        // eslint-disable-next-line no-restricted-globals
        localStorageKeysToChars: Object.keys(localStorage).map(
          (localStorageKey) => ({
            key: localStorageKey,
            // eslint-disable-next-line no-restricted-globals
            length: localStorage.getItem(key)?.length,
          }),
        ),
      });
    }
  };
  if (immediately) {
    performWrite();
  } else {
    setTimeout(() => {
      performWrite();
    });
  }
}

export function removeFromLocalStorage<Key extends LocalStorageKey>(key: Key) {
  const reactiveVariable = getReactiveVar(key);
  reactiveVariable(initialValues[key]);
  setTimeout(() => {
    try {
      localStorageWithMemoryFallback.removeItem(`engine:${key}`);
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error); // eslint-disable-line no-console
    }
  });
}

/**
 * Use a value persisted to localStorage, multiple callsites with the same key
 * will be kept in sync with any new values set.
 */
export function useLocalStorage<Key extends LocalStorageKey>(key: Key) {
  const parsedValue = useReactiveVar(getReactiveVar(key));
  const setValue = React.useCallback(
    (value: React.SetStateAction<typeof parsedValue>) => {
      writeToLocalStorage(key, value);
    },
    [key],
  );
  return [parsedValue, setValue] as const;
}

export function readFromLocalStorage<Key extends LocalStorageKey>(
  key: Key,
): Readonly<typeof initialValues[Key]> {
  return getReactiveVar(key)();
}

function isLocalStorageKey(key: string): key is LocalStorageKey {
  return key in initialValues;
}

const persistedLocalStorageKeys = new Set<LocalStorageKey>([
  'trackingConsent',
  // embedDefaultTabState contains the embed initialState. It is added to
  // localStorage from the embed query params.
  // On logout, the embed query params are scrapped, but
  // we want to keep the default op around for the logout state
  'embedDefaultTabState',
]);

export function clearLocalStorage() {
  Object.keys(localStorageWithMemoryFallback).forEach((engineKey) => {
    if (engineKey.startsWith('engine:')) {
      const key = engineKey.replace('engine:', '');
      if (isLocalStorageKey(key)) {
        if (!persistedLocalStorageKeys.has(key)) {
          removeFromLocalStorage(key);
        }
      } else {
        localStorageWithMemoryFallback.removeItem(engineKey);
      }
    }
  });
}

/**
 * Launchdarkley saves flags to localstorage for bootstrapping, when it does this it saves a key per unique identity.
 * We include buildTime in the identity, which means we have an infinitely increasing number of keys as users open the app in new builds.
 * This deletes all keys that use a buildTime that isn't current, because we will only see those again if there is a rollback
 */
export function deleteOldBuildTimeLaunchDarkleyCaches() {
  // If any errors occure during this cleanup, ignore them. They aren't important for runtime.
  try {
    const oldKeys = Object.keys(localStorageWithMemoryFallback).filter(
      (key) => {
        // the format of the key is ld:ENVIRONMENT_KEY:IDENTITY and IDENTITY is base64 encoded
        const match = key.match(/ld:[^:]*:(.*)/);

        if (isMinLengthArray(2, match) && match[1] !== '$diagnostics') {
          const encodedKey = match[1];
          if (
            encodedKey === 'eyJrZXkiOiJhbm9uLXN0dWRpby1zdGFnaW5nLXVzZXIifQ==' // '{"key":"anon-studio-staging-user"}'
          ) {
            // these keys are creating a bunch of errors in sentry, but I believe they can't be created anymore. so lets ignore them.
            return false;
          }
          try {
            const decoded = JSON.parse(atob(encodedKey)) as {
              custom: { buildTime: number };
            };
            const keyBuildTime = decoded.custom.buildTime;
            if (typeof keyBuildTime !== 'number') {
              throw new Error(
                'failed to parse ld key, buildTime is not a number',
              );
            }
            return keyBuildTime !== Config.buildTimestamp;
          } catch (err) {
            captureException(err, { extra: { key } });
          }
        }
        return false;
      },
    );
    if (process.env.NODE_ENV !== 'production' && oldKeys.length) {
      console.log(`deleting ${oldKeys.length} old launchdarkley keys`, oldKeys); // eslint-disable-line no-console
    }
    oldKeys.forEach((key) => localStorageWithMemoryFallback.removeItem(key));
  } catch (err) {
    captureException(err);
  }
}
