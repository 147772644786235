import { gql, useQuery } from '@apollo/client';
import { useRouteMatch } from 'react-router';

import { isEmbeddableExplorerRoute } from 'src/app/embeddableExplorer/isEmbeddableExplorerRoute';
import { useGraphVisibility } from 'src/app/graph/hooks/useGraphVisibility';
import { sandboxGraphRouteConfig } from 'src/app/graph/routes';
import { useIdentity } from 'src/hooks/useIdentity';
import { ignorePermissionsErrors } from 'src/lib/apollo/catchErrors';
import { appLinkContext } from 'src/lib/apollo/link';
import Config from 'src/lib/config';
import { GraphQLTypes } from 'src/lib/graphqlTypes';

export type AccountPermission = Omit<
  GraphQLTypes.UsePermissionsAccountQuery_account_roles,
  '__typename'
>;
export type GraphPermission = Omit<
  GraphQLTypes.UsePermissionsServiceQuery_service_roles,
  '__typename'
>;
export type GraphVariantPermission = Omit<
  GraphQLTypes.UsePermissionsServiceQuery_service_variant_permissions,
  '__typename'
>;

const UNAUTHENTICATED_GRAPH_PERMISSIONS: GraphPermission = {
  canCheckSchemas: true,
  canCreateVariants: true,
  canDelete: true,
  canCreateProposal: false,
  canEditProposal: false,
  canManageAccess: true,
  canManageIntegrations: true,
  canManageKeys: true,
  canManageProposalPermissions: false,
  canManageProposalSettings: false,
  canManageVariants: true,
  canQueryCheckConfiguration: true,
  canQueryDeletedImplementingServices: true,
  canQueryImplementingServices: true,
  canQueryIntegrations: true,
  canQueryPrivateInfo: true,
  canQueryPublicInfo: true,
  canQueryRoleOverrides: true,
  canQuerySchemas: true,
  canQueryStats: true,
  canQueryTokens: true,
  canQueryTraces: true,
  canRegisterOperations: true,
  canUndelete: true,
  canUpdateAvatar: true,
  canUpdateDescription: true,
  canUpdateTitle: true,
  canWriteCheckConfiguration: true,
  canQueryReadmeAuthor: false,
  canQueryProposals: false,
  canQueryPersistedQueryLists: true,
  canManagePersistedQueryLists: true,
};

const UNAUTHENTICATED_VARIANT_PERMISSIONS: GraphVariantPermission = {
  canManageExplorerSettings: true,
  canPushSchemas: true,
  canUpdateVariantReadme: false,
  canUpdateVariantLinkInfo: false,
  canManageBuildConfig: false,
  canQueryBuildConfig: false,
  canManageCloudRouter: false,
  canQueryCloudRouter: false,
  canQuerySchemas: true,
  canQueryLaunches: false,
  canEditProposal: false,
};

const PUBLIC_PREVIEW_ACCOUNT_PERMISSIONS: AccountPermission = {
  canCreateService: false,
  canDelete: false,
  canQuery: false,
  canManageMembers: false,
  canUpdateBillingInfo: false,
  canUpdateMetadata: false,
  canQueryMembers: false,
  canQueryBillingInfo: false,
  canSetConstrainedPlan: false,
  canRemoveMembers: false,
  canAudit: false,
  canQueryStats: false,
  canReadTickets: false,
  canProvisionSSO: false,
};

const PUBLIC_PREVIEW_GRAPH_PERMISSIONS: GraphPermission = {
  canCheckSchemas: false,
  canCreateVariants: false,
  canDelete: false,
  canCreateProposal: false,
  canEditProposal: false,
  canQueryProposals: false,
  canManageAccess: false,
  canManageIntegrations: false,
  canManageKeys: false,
  canManageProposalPermissions: false,
  canManageProposalSettings: false,
  canManageVariants: false,
  canQueryCheckConfiguration: false,
  canQueryDeletedImplementingServices: false,
  canQueryImplementingServices: false,
  canQueryIntegrations: false,
  canQueryPrivateInfo: true,
  canQueryPublicInfo: true,
  canQueryRoleOverrides: true,
  canQuerySchemas: true,
  canQueryStats: false,
  canQueryTokens: false,
  canQueryTraces: false,
  canRegisterOperations: false,
  canUndelete: false,
  canUpdateAvatar: false,
  canUpdateDescription: false,
  canUpdateTitle: false,
  canWriteCheckConfiguration: false,
  canQueryReadmeAuthor: false,
  canQueryPersistedQueryLists: false,
  canManagePersistedQueryLists: false,
};

const PUBLIC_PREVIEW_VARIANT_PERMISSIONS: GraphVariantPermission = {
  canManageExplorerSettings: false,
  canPushSchemas: false,
  canUpdateVariantReadme: false,
  canUpdateVariantLinkInfo: false,
  canQueryBuildConfig: false,
  canManageBuildConfig: false,
  canQueryCloudRouter: false,
  canManageCloudRouter: false,
  canQuerySchemas: true,
  canQueryLaunches: false,
  canEditProposal: false,
};

export function usePermissions(
  accountId: string | undefined,
  graphId: string | undefined,
  graphVariant: string | undefined,
  skip: boolean = false,
): {
  data: {
    accountPermissions?: AccountPermission;
    accountRole?: GraphQLTypes.UserPermission;
    graphPermissions?: GraphPermission;
    graphVariantIsProtected?: boolean;
    graphRole?: GraphQLTypes.UserPermission;
    graphVariantPermissions?: GraphVariantPermission;
  };
  loading: boolean;
  error: Error | undefined;
} {
  const {
    data: accountData,
    loading: accountLoading,
    error: accountError,
  } = useQuery<
    GraphQLTypes.UsePermissionsAccountQuery,
    GraphQLTypes.UsePermissionsAccountQueryVariables
  >(
    gql`
      query UsePermissionsAccountQuery($accountId: ID!) {
        account(id: $accountId) {
          id
          roles {
            canCreateService
            canDelete
            canQuery
            canManageMembers
            canUpdateBillingInfo
            canUpdateMetadata
            canQueryMembers
            canQueryBillingInfo
            canSetConstrainedPlan
            canRemoveMembers
            canAudit
            canQueryStats
            canReadTickets
            canProvisionSSO
          }
        }
      }
    `,
    {
      context: appLinkContext({ catchErrors: [ignorePermissionsErrors] }),
      variables: {
        accountId: accountId as string, // Safe because of the skip check below
      },
      skip: skip || accountId === undefined,
    },
  );

  const {
    data: serviceData,
    loading: serviceLoading,
    error: serviceError,
  } = useQuery<
    GraphQLTypes.UsePermissionsServiceQuery,
    GraphQLTypes.UsePermissionsServiceQueryVariables
  >(
    gql`
      query UsePermissionsServiceQuery(
        $serviceId: ID!
        $graphVariant: String!
      ) {
        service(id: $serviceId) {
          id
          account {
            id
          }
          roleOverrides {
            role
            user {
              id
            }
          }
          roles {
            canCreateVariants
            canCheckSchemas
            canDelete
            canCreateProposal
            canEditProposal
            canQueryProposals
            canManageAccess
            canManageIntegrations
            canManageKeys
            canManageProposalPermissions
            canManageVariants
            canQueryCheckConfiguration
            canQueryDeletedImplementingServices
            canQueryImplementingServices
            canQueryIntegrations
            canQueryPrivateInfo
            canQueryPublicInfo
            canQueryRoleOverrides
            canQuerySchemas
            canQueryTokens
            canRegisterOperations
            canUndelete
            canUpdateAvatar
            canUpdateTitle
            canUpdateDescription
            canWriteCheckConfiguration
            canQueryStats
            canQueryTraces
            canQueryReadmeAuthor
            canQueryPersistedQueryLists
            canManagePersistedQueryLists
            canManageProposalSettings
          }
          variant(name: $graphVariant) {
            id
            isProtected
            permissions {
              canPushSchemas
              canManageExplorerSettings
              canUpdateVariantReadme
              canUpdateVariantLinkInfo
              canManageBuildConfig
              canQueryBuildConfig
              canManageCloudRouter
              canQueryCloudRouter
              canQuerySchemas
              canQueryLaunches
              canEditProposal
            }
          }
        }
      }
    `,
    {
      context: appLinkContext({ catchErrors: [ignorePermissionsErrors] }),
      variables: {
        serviceId: graphId as string, // Safe because of the skip check below
        graphVariant: graphVariant ?? Config.service.defaultVariant,
      },
      skip: skip || graphId === undefined,
    },
  );

  const { me, meLoading, error: meError, isUser, memberships } = useIdentity();

  const isSandboxRoute =
    useRouteMatch(sandboxGraphRouteConfig.definition) !== null;
  const isEmbeddableRoute = isEmbeddableExplorerRoute();

  // If we are not operating on a registered graph, we can take any actions.
  const shouldUseUnauthenticatedPermissions =
    (isSandboxRoute || isEmbeddableRoute) && !graphId;

  // If we are on public route but have permission to view the account we are
  // previewing. Special case to lower permissions.
  const shouldUsePublicPreviewPermissions =
    useGraphVisibility() === 'public' &&
    serviceData?.service?.account !== undefined;

  const membership = isUser
    ? memberships.find(
        ({ account }) =>
          account.id === (accountId ?? serviceData?.service?.account?.id),
      )
    : undefined;

  const graphVariantIsProtected =
    graphVariant === undefined
      ? undefined
      : serviceData?.service?.variant?.isProtected ?? undefined;
  const loading = meLoading || accountLoading || serviceLoading;
  const error = meError || accountError || serviceError;

  // Special case permissions for public preview to give the user less access
  if (shouldUsePublicPreviewPermissions) {
    return {
      data: {
        accountPermissions: PUBLIC_PREVIEW_ACCOUNT_PERMISSIONS,
        accountRole: undefined,
        graphPermissions: PUBLIC_PREVIEW_GRAPH_PERMISSIONS,
        graphRole: undefined,
        graphVariantIsProtected,
        graphVariantPermissions: PUBLIC_PREVIEW_VARIANT_PERMISSIONS,
      },
      loading,
      error,
    };
  }

  const graphRole = serviceData?.service
    ? serviceData.service.roleOverrides?.find(({ user }) => user.id === me?.id)
        ?.role ?? membership?.role
    : undefined;

  if (shouldUseUnauthenticatedPermissions) {
    return {
      data: {
        accountPermissions: accountData?.account?.roles ?? undefined,
        accountRole: membership?.role,
        graphPermissions: UNAUTHENTICATED_GRAPH_PERMISSIONS,
        graphRole,
        graphVariantIsProtected,
        graphVariantPermissions: UNAUTHENTICATED_VARIANT_PERMISSIONS,
      },
      loading,
      error,
    };
  }

  const graphVariantPermissions =
    graphVariant === undefined
      ? undefined
      : serviceData?.service?.variant
      ? serviceData.service.variant.permissions
      : {
          // The semantics we have recently implemented are that uploadSchema
          // to existing variants uses variant.canPushSchemas but for new variants
          // (upsert) it's graph.canCreateVariants. The point being that
          // contributors should be able to "upsert" new variant via uploadSchema.
          canPushSchemas:
            serviceData?.service?.roles?.canCreateVariants ?? false,
          canManageExplorerSettings: false,
          canUpdateVariantReadme: false,
          canEditProposal: false,
          canUpdateVariantLinkInfo: false,
          canManageBuildConfig: false,
          canQueryBuildConfig: false,
          canManageCloudRouter: false,
          canQueryCloudRouter: false,
          canQuerySchemas:
            serviceData?.service?.roles?.canQuerySchemas ?? false,
          canQueryLaunches: false,
        };

  return {
    data: {
      accountPermissions: accountData?.account?.roles ?? undefined,
      accountRole: membership?.role,
      graphPermissions: serviceData?.service?.roles ?? undefined,
      graphRole,
      graphVariantIsProtected,
      graphVariantPermissions,
    },
    loading,
    error,
  };
}
